






































































import {
  Component, Mixins, Watch, Prop,
} from 'vue-property-decorator';
import { COMPONENTS_REDACTION } from './constants';

import Arrow from '@/assets/icons/Arrow.vue';

import Loading from '@/components/Loading/Loading.vue';

import ModalWarning from '../Modals/ModalWarning.vue';
import ModalChangeSendRedaction from '../Modals/ModalChangeSendRedaction.vue';

import RedactionDigited from './components/RedactionDigited/RedactionDigited.vue';
import ImageRedaction from './components/ImageRedaction/ImageRedaction.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import permission from '@/mixins/permission';
import { LIST_PERMISSION } from '@/constant/ListPermission';

interface DataRedaction {
  isLimitText: boolean;
  contentRedaction: string;
  haveMinimumWord: boolean;
}

const COMPONENT_REDACTION_DIGITED = 'RedactionDigited';
const ROUTE_NAME_SIMULATED_QUESTION = 'SimulatedQuestions';

const DEFAULT_FILL = 'var(--primarycolor-pure)';

@Component({
  components: {
    RedactionDigited,
    ImageRedaction,
    Loading,
    ModalWarning,
    ModalChangeSendRedaction,
    Arrow,
  },
})
export default class ContainerSendRedaction extends Mixins(permission) {
  @Prop({ default: false }) isLoadingButton!: boolean;
  @Prop({ default: true }) showBtnBack!: boolean;
  @Prop({ default: true }) showBtnSend!: boolean;
  @Prop({ default: false }) timesUpAndSendRedaction!: boolean;
  @Prop({ default: '' }) routeName!: string;

  private contentRedaction = '';
  private urlImageRedaction = '';
  private isDisableSending = false;
  private isLimitText = false;
  private haveMinimumWord = false;
  private canEmitRedaction = false;
  private DEFAULT_FILL = DEFAULT_FILL;

  private styleNavigation = {
    justifyContent: 'flex-end',
  }

  private componentRedaction = COMPONENTS_REDACTION;

  private setModal = new ActiveModal();

  mounted() {
    this.getTotalRedactionSend();
  }

  get showFeedback() {
    return this.selectedComponentSendRedaction === this.componentRedaction.digited
      && this.isLimitText
      && this.contentRedaction;
  }

  get selectedComponentSendRedaction() {
    const component = this.$store.getters.redactionSubmissionSettings.component || sessionStorage.getItem('componentSendRedaction');

    if (component) return component;

    return COMPONENT_REDACTION_DIGITED;
  }

  get isDisableButton() {
    return this.isDisableSendButton || !this.hasCreditsRedactionSend;
  }

  get isDisableSendButton() {
    return this.isDisableSending || this.isLoadingButton;
  }

  get getTotalCreditsRedaction(): number | null {
    const total = this.getQuantityPermission(LIST_PERMISSION.REDACTION_CREDITS);

    return total;
  }

  get hasCreditsRedactionSend() {
    const totalCredits = this.getTotalCreditsRedaction;
    if (!totalCredits) return false;

    const resultCredits = totalCredits - this.totalRedactionMeMonthly;

    return resultCredits > 0;
  }

  get totalRedactionMeMonthly() {
    return Number(this.$store.getters.totalRedactionsMeMonthly);
  }

  async getTotalRedactionSend() {
    try {
      const response = await this.RedactionService.getTotalRedactionSend();

      if (response?.total_redactions_me_monthly) {
        this.setStoreTotalRedactionSend(response?.total_redactions_me_monthly);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar quantidades de créditos.',
        status: 'error',
      });
      console.error(error);
    }
  }

  setStoreTotalRedactionSend(quantity: number) {
    this.$store.commit('setTotalRedactionsMeMonthly', quantity);
  }

  @Watch('selectedComponentSendRedaction', {
    immediate: true,
  })
  setComponentAssembly() {
    if (this.selectedComponentSendRedaction === COMPONENT_REDACTION_DIGITED) {
      this.isDisableSending = this.isLimitText;
    } else {
      this.isDisableSending = !this.urlImageRedaction;
    }
  }

  showModalChangeComponent() {
    this.setModal.activeModal('modalChangeSendRedaction');
  }

  getDataRedactionDigitatedAndEmit(dataRedaction: DataRedaction) {
    const { isLimitText, contentRedaction, haveMinimumWord } = dataRedaction;

    this.contentRedaction = contentRedaction;
    this.isLimitText = isLimitText;
    this.isDisableSending = isLimitText;
    this.haveMinimumWord = haveMinimumWord;
  }

  getImageRedactionAndEmit(value: string) {
    if (this.selectedComponentSendRedaction === this.componentRedaction.image) {
      this.urlImageRedaction = value;
      this.isDisableSending = !value;
    }
  }

  changeComponentSendRedaction() {
    const timeSelected = this.$store.getters.redactionSubmissionSettings.time || sessionStorage.getItem('timeSendRedaction');

    if (this.selectedComponentSendRedaction === this.componentRedaction.digited) {
      this.contentRedaction = '';
      this.isLimitText = false;
      this.isDisableSending = false;
      this.haveMinimumWord = false;

      this.emitEventContentRedaction();
      this.$store.commit('setRedactionSubmissionSettings', {
        component: this.componentRedaction.image,
        time: timeSelected,
      });
    } else {
      this.emitEventContentRedaction();
      this.urlImageRedaction = '';
      this.$store.commit('setRedactionSubmissionSettings', {
        component: this.componentRedaction.digited,
        time: timeSelected,
      });
    }
  }

  verifyComponentSendRedaction() {
    if (this.selectedComponentSendRedaction === this.componentRedaction.digited && !this.haveMinimumWord) this.showModalWarning();
    else {
      this.canEmitRedaction = true;
      this.emitEventContentRedaction();
    }
  }

  @Watch('timesUpAndSendRedaction')
  @Watch('urlImageRedaction')
  @Watch('contentRedaction')
  emitEventContentRedaction() {
    if (this.routeName === ROUTE_NAME_SIMULATED_QUESTION || this.canEmitRedaction || this.timesUpAndSendRedaction) {
      this.$emit('sendRedaction', {
        type: this.selectedComponentSendRedaction === this.componentRedaction.digited ? 'content' : 'URL',
        content: this.selectedComponentSendRedaction === this.componentRedaction.digited ? this.contentRedaction : this.urlImageRedaction,
      });
    }
  }

  showModalWarning() {
    this.setModal.activeModal('modalRedactionWarning');
  }

  goBack() {
    this.$emit('goBack');
  }
}
