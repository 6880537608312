


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class QuestionSizePositive extends Vue {
  @Prop({ default: '#292D35' }) fill!: string;
}
