







































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EyeSlash extends Vue {
  @Prop() fill!: string;
  @Prop({ default: 'Exibir' }) title!: string;
}
