



























































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Instruction from '@/components/Instruction/Instruction.vue';

import ContainerInstructionProposalLoading from './ContainerInstructionProposalLoading.vue';
import ProposalRedaction from '../ProposalRedaction/ProposalRedaction.vue';
import PrintRedactionSheet from '../PrintRedactionSheet/PrintRedactionSheet.vue';
import ModalOptionSendRedaction from '../Modals/ModalOptionSendRedaction/index.vue';
import ModalConfigSendRedaction from '../Modals/ModalConfigSendRedaction/index.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import { Redaction } from '../interface/IRedactionDescription';
import LIST_INSTRUCTIONS from './InstructionsRedaction';

import RedactionService from '@/services/Redaction/RedactionService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { Theme as ITheme } from '@/pages/Redaction/interfaces/IRedactionPreview';

const COUNTER_INITIAL_VALUE = 5;
const ROUTE_NAME_REDACTION = 'RedactionInstruction';
const ROUTE_NAME_SIMULATED = 'SimulatedQuestions';
const COMPONENT_MODAL_REDACTION = 'ModalConfigSendRedaction';
const COMPONENT_MODAL_SIMULATED = 'ModalOptionSendRedaction';

@Component({
  components: {
    ContainerInstructionProposalLoading,
    FeedbackUser,
    Instruction,
    ProposalRedaction,
    PrintRedactionSheet,
    ModalOptionSendRedaction,
    ModalConfigSendRedaction,
  },
})
export default class ContainerInstructionProposal extends Vue {
  @Prop() themeId!: number;
  @Prop() titleHeader!: string;
  @Prop() tabProposalIsActive!: boolean;

  private themeIsSimulated = false;
  private isLoadingRedactionDescription = true;
  private redaction_description: Redaction | null = null;
  private secondsCounter = COUNTER_INITIAL_VALUE;

  private listInstruction = LIST_INSTRUCTIONS;

  private setModal = new ActiveModal();
  private RedactionService = new RedactionService();

  created() {
    this.getRedactionDescription();
    this.getRedactionsSimulated();
  }

  get feedbackErrorRedactionDescription() {
    return `Falha ao carregar o conteúdo. Uma nova tentativa será feita em ${this.secondsCounter} segundos.`;
  }

  get routeName() {
    return this.$route.name;
  }

  async getRedactionDescription() {
    try {
      this.isLoadingRedactionDescription = true;
      const response = await this.RedactionService.getRedactionDescription(this.themeId);
      if (response) this.redaction_description = response;
    } catch (error) {
      console.error(error);
      this.checkSecondsCounterAndCallTimeCounter();
    } finally {
      this.isLoadingRedactionDescription = false;
    }
  }

  async getRedactionsSimulated() {
    try {
      if (this.routeName === ROUTE_NAME_REDACTION) {
        const response: ITheme[] = await this.RedactionService.getThemesOfSimulated();

        this.themeIsSimulated = this.verifyThemeIsSimulated(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  verifyThemeIsSimulated(listThemes: Array<ITheme>) {
    const { themeId } = this.$route.params;

    return listThemes.some((theme: ITheme) => theme.id === Number(themeId));
  }

  checkSecondsCounterAndCallTimeCounter() {
    if (this.secondsCounter === 0) this.secondsCounter = COUNTER_INITIAL_VALUE;
    else this.timeCounter();
  }

  @Watch('secondsCounter')
  timeCounter() {
    if (this.secondsCounter === 0) {
      this.getRedactionDescription();
      return;
    }

    setTimeout(() => {
      this.secondsCounter -= 1;
    }, 1000);
  }

  beginRedaction() {
    if (this.routeName === ROUTE_NAME_REDACTION) {
      this.setTrackAmplitude();

      const { themeId } = this.$route.params;

      this.$router.push({
        name: 'RedactionSend',
        params: {
          themeId,
        },
      });

      return;
    }

    this.$emit('open-input-redaction');
  }

  showModal() {
    this.setModal.activeModal(this.routeName === ROUTE_NAME_SIMULATED ? COMPONENT_MODAL_SIMULATED : COMPONENT_MODAL_REDACTION);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ContainerInstructionProposal',
          local: 'Começar a redação',
        },
      },
    });
  }
}
