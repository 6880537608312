export default class ScrollControl {

  scrollTopPage(optionsScroll?: Record<string, any>) {
    const el = document.getElementById('top-0') as HTMLElement;
    el.scrollIntoView(optionsScroll);
  }

  scrollToElement(id: string, optionsScroll?: Record<string, any>) {
    const el = document.getElementById(id) as HTMLElement;
    el.scrollIntoView(optionsScroll);
  }
}
