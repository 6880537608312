









































import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Loading from '@/components/Loading/Loading.vue';

import { SIMULATED_CONSTANT } from '../../constants/SimulatedQuestion';

@Component({
  components: {
    Modal,
    Loading,
  },
})
export default class ModalSuccessSimulated extends Vue {
  @Prop() simulatedDay!: number;
  @Prop() modalTextSuccessSendSimulated!: string;
  @Prop() isWaitingSecondDaySimulated!: boolean;
  @Prop({ default: false }) canGoToPageResult!: boolean;

  private isLoadingDashboard = false;
  private isLoadingNextPageSimulated = false;

  beforeDestroy() {
    this.$store.commit('saveInfoSimulatedQuestion', null);
  }

  get isFirstDay() {
    return this.simulatedDay === SIMULATED_CONSTANT.FIRST_DAY_SIMULATED;
  }

  get textButton() {
    if (this.isFirstDay && !this.isWaitingSecondDaySimulated) {
      return SIMULATED_CONSTANT.TEXT_BUTTON_BEGIN_NOW;
    }

    if (!this.isFirstDay && this.canGoToPageResult) {
      return SIMULATED_CONSTANT.TEXT_BUTTON_VIEW_RESULT;
    }

    return '';
  }

  goToDashboard() {
    this.isLoadingDashboard = true;
    this.$router.push({
      name: SIMULATED_CONSTANT.NAME_ROUTE_DASHBOARD,
    });
  }

  goToNextPageSimulated() {
    this.isLoadingNextPageSimulated = true;

    if (this.isFirstDay) {
      this.$router.push({
        name: SIMULATED_CONSTANT.NAME_ROUTE_INSTRUCTION,
        params: {
          id: this.$route.params.id,
        },
      });

      this.$store.commit('setPermanenceSimulatedData', true);

      return;
    }

    this.$emit('click-to-view-result');
  }
}
