





























import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalWarningQuestion extends Vue {
  @Prop() simulatedDay!: number;

  handleClickButtonFinish() {
    this.$emit('click-button-finish');
  }
}
