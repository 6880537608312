



































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalChangeSendRedaction extends Vue {
  changeComponentSendRedaction() {
    this.$emit('changeComponent');
  }
}
