



































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SizeText extends Vue {
  @Prop() fill!: string;

}
