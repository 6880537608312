export const STATUS_SKIP = 'skip';
export const STATUS_ANSWERED = 'answered';

export const CLASS_SKIP = '--skip';
export const CLASS_ANSWERED = '--answered';
export const CLASS_INCORRECT = '--incorrect';
export const CLASS_CURRENT = '--current';
export const CLASS_ACTIVE = '--active';

export const NUMBER_OPTIONS_CORRECT = 1;
