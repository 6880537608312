import { render, staticRenderFns } from "./SimulatedQuestionLoading.vue?vue&type=template&id=52454c50&scoped=true&"
import script from "./SimulatedQuestionLoading.vue?vue&type=script&lang=ts&"
export * from "./SimulatedQuestionLoading.vue?vue&type=script&lang=ts&"
import style0 from "./SimulatedQuestionLoading.scss?vue&type=style&index=0&id=52454c50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52454c50",
  null
  
)

export default component.exports