const COMPONENT_QUESTION = 'ContainerQuestion';
const COMPONENT_REDACTION_INSTRUCTION = 'ContainerInstructionProposal';
const COMPONENT_SEND_REDACTION = 'ContainerSendRedaction';

const INDEX_FIRST_DAY_SIMULATED = 0;
const INDEX_SECOND_DAY_SIMULATED = 1;
const NEXT_PREVIOUS_TAB_NUMBER = 1;

const TAB_INDEX_RESET = 0;

const INDEX_TAB_REDACTION = 1;

const FIRST_DAY_SIMULATED = 1;
const SECOND_DAY_SIMULATED = 2;

const ID_LANGUAGE_SPANISH = 13;
const ID_LANGUAGE_ENGLISH = 3;
const ID_LANGUAGE_NOT_SELECTED = 0;

const THEME_REDACTION_ZERO = 0;
const QUANTITY_QUESTIONS_LANGUAGENS_EN_ES = 5;

const QUESTION_ANSWERED = 'answered';
const QUESTION_SKIP = 'skip';

const FORMAT_SHOWING_QUESTIONS = 'all';

const COMPONENT_MODAL_WARNING_REDACTION = 'modalWarningRedaction';
const COMPONENT_MODAL_WARNING_QUESTION = 'modalWarningQuestion';
const COMPONENT_MODAL_FINISHED_TIME = 'ModalTimeFinishSimulated';
const COMPONENT_MODAL_SUCCESS = 'ModalSuccessSimulated';

const NAME_FIRST_DAY = 'primeiro';
const NAME_SECOND_DAY = 'segundo';
const TAB_ID_REDACTION = 'redacao';

const TAB_REDACTION = {
  slug: '',
  id: 'redacao',
  title: 'Redação',
  active: false,
};

const LIST_TAB = [
  { title: 'Linguagens', id: 'lc_lt', active: false },
  { title: 'Natureza', id: 'cn_ct', active: false },
  { title: 'Humanas', id: 'ch_ct', active: false },
  { title: 'Matemática', id: 'mt', active: false },
];

const TAB_NAME_LANGUAGES = 'lc_lt';

const MODAL_TEXT_FIRST_DAY_SUCCESSFUL_SENDING_SIMULATED = ', a segunda prova será realizada no dia ';
const MODAL_TEXT_SECOND_DAY_SUCCESSFUL_SENDING_SIMULATED = ', o resultado da prova sairá  no dia ';
const MODAL_TEXT_SECOND_DAY_AVAILABLE = ' e a segunda prova já está disponível. Quer começá-la agora?';
const MODAL_TEXT_GO_TO_PAGE_RESULT = ' e o resultado já está disponível!';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const TEXT_BUTTON_VIEW_RESULT = 'Ver o resultado';
const TEXT_BUTTON_BEGIN_NOW = 'Começar agora';
const NAME_ROUTE_INSTRUCTION = 'SimulatedInstruction';
const NAME_ROUTE_RESULT = 'SimulatedResult';
const NAME_ROUTE_DASHBOARD = 'Dashboard';

const PERMISSION_SAVE_GET_DATA_STORE = true;
const MSG_ERRO_SIMULATED = 'Erro ao carregar simulado. Tente novamente em instantes.';

export const SIMULATED_CONSTANT = {
  COMPONENT_QUESTION,
  COMPONENT_REDACTION_INSTRUCTION,
  COMPONENT_SEND_REDACTION,
  INDEX_FIRST_DAY_SIMULATED,
  INDEX_SECOND_DAY_SIMULATED,
  NEXT_PREVIOUS_TAB_NUMBER,
  INDEX_TAB_REDACTION,
  TAB_INDEX_RESET,
  FIRST_DAY_SIMULATED,
  SECOND_DAY_SIMULATED,
  ID_LANGUAGE_SPANISH,
  ID_LANGUAGE_ENGLISH,
  ID_LANGUAGE_NOT_SELECTED,
  THEME_REDACTION_ZERO,
  QUANTITY_QUESTIONS_LANGUAGENS_EN_ES,
  QUESTION_ANSWERED,
  QUESTION_SKIP,
  FORMAT_SHOWING_QUESTIONS,
  COMPONENT_MODAL_WARNING_REDACTION,
  COMPONENT_MODAL_WARNING_QUESTION,
  COMPONENT_MODAL_FINISHED_TIME,
  COMPONENT_MODAL_SUCCESS,
  NAME_FIRST_DAY,
  NAME_SECOND_DAY,
  TAB_ID_REDACTION,
  TAB_REDACTION,
  TAB_NAME_LANGUAGES,
  MODAL_TEXT_FIRST_DAY_SUCCESSFUL_SENDING_SIMULATED,
  MODAL_TEXT_SECOND_DAY_SUCCESSFUL_SENDING_SIMULATED,
  MODAL_TEXT_SECOND_DAY_AVAILABLE,
  MODAL_TEXT_GO_TO_PAGE_RESULT,
  DATE_FORMAT,
  LIST_TAB,
  TEXT_BUTTON_VIEW_RESULT,
  TEXT_BUTTON_BEGIN_NOW,
  NAME_ROUTE_INSTRUCTION,
  NAME_ROUTE_RESULT,
  NAME_ROUTE_DASHBOARD,
  PERMISSION_SAVE_GET_DATA_STORE,
  MSG_ERRO_SIMULATED,
};
