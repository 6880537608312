






















import { Component, Vue, Prop } from 'vue-property-decorator';

import QuestionSizeNegative from '@/assets/icons/resizeText/QuestionSizeNegative.vue';
import QuestionSizePositive from '@/assets/icons/resizeText/QuestionSizePositive.vue';
import QuestionSizeSymbol from '@/assets/icons/resizeText/QuestionSizeSymbol.vue';

const COLOR_ENABLED_ICON = 'var(--neutralcolor-low-pure)';
const COLOR_DISABLE_ICON = 'var(--neutralcolor-high-dark)';

@Component({
  components: {
    QuestionSizeNegative,
    QuestionSizePositive,
    QuestionSizeSymbol,
  },
})
export default class ResizeText extends Vue {
  @Prop({ required: true }) isMaximumFontSize!: boolean;
  @Prop({ required: true }) isMinimumFontSize!: boolean;

  get changeColorMaximum() {
    return this.isMaximumFontSize ? COLOR_DISABLE_ICON : COLOR_ENABLED_ICON;
  }

  get changeColorMinimum() {
    return this.isMinimumFontSize ? COLOR_DISABLE_ICON : COLOR_ENABLED_ICON;
  }

  increaseText() {
    this.$emit('increase-text');
  }

  decreaseText() {
    this.$emit('decrease-text');
  }
}
