























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class RadioBox extends Vue {
  @Prop({ required: true }) radiolist!: Record<string, any>

  private optionPicked = ''

  mounted() {
    this.setOptionPicked();
  }

  @Watch('optionPicked')
  emitOptionPicked() {
    this.$emit('optionPicked', this.optionPicked, this.radiolist.name);
  }

  setOptionPicked() {
    if (this.radiolist) {
      const itemChecked = this.radiolist.array.filter((item: Record<string, any>) => item.checked);
      this.optionPicked = itemChecked[0].value;
    }
  }
}
